import React, { useState, useContext } from "react"
import Layout from "@components/Layout/Layout";
import Container from "@components/Container/Container.jsx";
import Button from "@components/Buttons/Button.jsx"
import ButtonLink from "@components/Buttons/ButtonLink.jsx"
import MainWrap from "@components/Wrap/MainWrap";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import Chip from "@components/Cards/Chip.jsx"
import Avatar from "@material-ui/core/Avatar";
import { makeComponentStyles } from "@components/mixins.js"
import clsx from "clsx";
import { IntlContextConsumer, useIntl } from "gatsby-plugin-react-intl"
import { getStrapiContentByLang } from "../heplers.js"
import infoLabel from "@static/images/Info.svg"
import { graphql } from "gatsby";
import Markdown from "markdown-to-jsx";
import FloatWrap from "@components/Wrap/FloatWrap.jsx";
import Seo from "@components/seo"

const styles = ({ palette, breakpoints }) => ({
    text: {
        fontSize: "18px",
        color: palette.text.primary,
        '& $b': {
            fontWeight: 500
        },
        [breakpoints.down('sm')]: {
            fontSize: "14px",
            color: palette.text.black
        }
    },
    chiptext: {
        fontSize: "18px",
        [breakpoints.down('sm')]: {
            fontSize: "14px"
        }
    },
    title: {
        fontSize: '22px',
        fontWeight: 500,
        margin: '40px 0 20px',
    },
    appendix: {
        [breakpoints.up('md')]: {
            textAlign: 'center',
            margin: '0 173px'
        }
    },
    linker: {
        color: palette.primary.main,
        [breakpoints.up('md')]: {
            fontSize: '18px !important',
        }
    },
    card: {
        [breakpoints.down('sm')]: {
            padding: 0,
        },
        [breakpoints.up('md')]: {
            padding: '20px 24px',
            background: palette.primary.light,
            display: 'flex',
            alignItems: 'center',
            height: '100%'
        }
    }
});

function go(link) {
    window.open(link, '_blank');
}

const Pharmacy = ({ data }) => {
    const {
        text,
        chiptext,
        title,
        appendix,
        linker,
        card
    } = makeComponentStyles(styles);

    const [visible, setVisible] = useState(false);
    const { language } = useContext(IntlContextConsumer);
    const intl = useIntl();

    const {
        Info,
        MoreInfo,
        Image,
        Important,
        Pharmacies,
        PharmacySEO
    } = getStrapiContentByLang(data.allStrapiPharmacy.edges, language);

    return (
      <div>
          <Seo title={PharmacySEO?.TitleSEO}
               description={PharmacySEO?.DescriptionSEO}
               keywords={PharmacySEO?.KeywordsSEO.split(',')}
          />

          <>
              <Layout>
                  <Container>
                      <MainWrap>
                          <MainWrap.Header>
                              <Typography component={"h1"} variant={"h2"}>
                                  {intl.formatMessage({ id: "pharmacy" })}
                              </Typography>
                          </MainWrap.Header>
                          <MainWrap.Body>
                              <Grid container spacing={3}>
                                  <Grid item xs={12}>
                                      <FloatWrap Img={Image.localFile.publicURL} Float='right' >
                                          <Markdown>{Info}</Markdown>
                                          <div hidden={!visible} style={{ marginTop: '20px' }}>
                                              <Markdown>{MoreInfo}</Markdown>
                                          </div>
                                          <div style={{ marginTop: '20px' }} align='center'>
                                              <Button onClick={() => setVisible(!visible)}>{visible ? intl.formatMessage({ id: "hide" }) : intl.formatMessage({ id: "show" })}</Button>
                                          </div>
                                      </FloatWrap>
                                  </Grid>
                                  <Grid item xs={12}>
                                      <Chip avatar={<Avatar src={infoLabel} />} label={intl.formatMessage({ id: 'important' })} straight className={chiptext} />
                                  </Grid>
                                  <Grid item xs={12}>
                                      <Typography className={clsx(text, appendix)}>
                                          <Markdown>{Important}</Markdown>
                                      </Typography>
                                  </Grid>
                              </Grid>

                              <Typography className={title}>{intl.formatMessage({ id: 'pharmacylist' })}:</Typography>
                              <Grid container spacing={3}>
                                  {
                                      Pharmacies.map(({
                                                          Title, Site, WorkingStart, WorkingEnd, Address
                                                      }) => (
                                        <Grid item xs={12} md={4}>
                                            <Container className={card}>
                                                <div>
                                                    <Typography className={text}><b>{Title}</b></Typography>
                                                    <Typography className={text}>{Address}</Typography>
                                                    <Typography className={text}>
                                                        {intl.formatMessage({ id: "workingtime" })}: {WorkingStart.substr(0, WorkingStart.lastIndexOf(':'))}-{WorkingEnd.substr(0, WorkingEnd.lastIndexOf(':'))}
                                                    </Typography>
                                                    {Site ? <ButtonLink className={linker} onClick={() => go(Site)}>{intl.formatMessage({ id: "site" })}</ButtonLink> : null}
                                                </div>
                                            </Container>
                                        </Grid>
                                      ))
                                  }
                              </Grid>
                          </MainWrap.Body>
                      </MainWrap>
                  </Container>
              </Layout>
          </>
      </div>
    )
}

export default Pharmacy;
export const pageQuery = graphql`{
  allStrapiPharmacy {
    edges {
      node {
        locale
        Info
        Important
        Image {
          localFile {
            publicURL
          }
        }
        MoreInfo
        Pharmacies {
          Address
          Site
          Title
          WorkingEnd
          WorkingStart
        }
        PharmacySEO {
          DescriptionSEO
          KeywordsSEO
          TitleSEO
        } 
      }
    }
  }
}`;
